import EnterPasswordDialogComponent from '@wix/thunderbolt-elements/src/components/EnterPasswordDialog/viewer/EnterPasswordDialog';


const EnterPasswordDialog = {
  component: EnterPasswordDialogComponent
};


export const components = {
  ['EnterPasswordDialog']: EnterPasswordDialog
};

